@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import "~leaflet/dist/leaflet.css";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '@core/scss/angular/libs/select.component.scss';
@import '@core/scss/angular/libs/datatables.component.scss';
@import '@core/scss/angular/libs/date-time-picker.component.scss';

.validationError{
  border-color: red;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
}

.redColor{
  color: red;
  font-size: 12px;
}

.dropdown-menu{
  height: 140px;
  overflow-y: auto;
}

.cdk-overlay-pane{
    // width: 80% !important;
  }

  
  .datatable-header{
    width: 100% !important;
  }
  
  .datatable-body{
    width: 100% !important;
  }

  
.dropdown-menu{
  height: auto !important;
}
.ngb-dp-weekday{
  color: var(--primary) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary) !important;
}
.btn-link{
  padding: 0px;
}
